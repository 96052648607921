.orderSection {
  margin-top: 40px;
  border-radius: 2px;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  margin-bottom: 100px;
  display: flex;
  flex-direction: column;
  min-height: 690px;
}

.cardheader {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 20px;
  border-bottom: 1px solid rgba(245, 245, 245, 1);
  border-radius: 5px;
  background-color: #fff;

  .leftside {
    h3 {
      font-size: 22px;
      line-height: 24px;
      color: #333333;
      letter-spacing: 0.5px;
      font-family: 'pangrammedium';
      margin: 0px 0px 2px;
    }
    p {
      font-size: 13px;
      line-height: 15px;
      letter-spacing: 0.3px;
      font-family: 'pangramregular';
      margin: 0px;
      color: #646464;
      text-transform: capitalize;
    }
  }
}

// new
.card {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e7e7e7;
  padding: 20px 0;
  border-radius: 0;
  position: relative;
  margin: 0 20px;
}

.productImage {
  width: 140px;
  height: 120px;
  object-fit: cover;
  border-radius: 3px;
  margin-right: 20px;
}

.productInfo {
  flex: 1;
  h2 {
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.5px;
    font-family: 'pangrammedium';
    font-weight: 500;
    margin: 0;
    color: #333333;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 99%;
  }
  p {
    color: #8b8b8b;
    line-height: 20px;
    font-size: 14px;
    font-weight: 400;
    margin: 0px 0 20px;
    letter-spacing: 0.5px;
    font-family: 'pangramregular';
  }
}

.rating {
  display: flex;
  align-items: center;
  margin: 0px 0 20px;
}

.ratingValue {
  background-color: #e27a34;
  color: #fff;
  padding: 2px 7px;
  border-radius: 100px;
  margin-right: 5px;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.5px;
  font-family: 'pangramregular';
}

.star {
  color: #fff;
  background-color: #e27a34;
  margin: 0 0 0 3px;
  border-radius: 4px;
  font-size: 14px;
}

.reviews {
  color: #515151;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  letter-spacing: 0.5px;
  font-family: 'pangrammedium';
}

.priceSection {
  display: flex;
  align-items: center;
}

.currentPrice {
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  letter-spacing: 0.5px;
  color: #333333;
  font-family: 'pangrammedium';
  margin: 0px;
}

.originalPrice {
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.5px;
  text-decoration: line-through;
  font-weight: 400;
  margin: 0px;
  padding: 0px 5px;
  color: #646464;
  font-family: 'pangramregular';
}

.discount {
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.5px;
  font-weight: 400;
  margin: 0px;
  color: #4caf4f;
  font-family: 'pangramregular';
}

.addToCartButton {
  background-color: #e27a34;
  color: #fff;
  border: none;
  padding: 12px 20px;
  border-radius: 3px;
  cursor: pointer;
  letter-spacing: 0.5px;
  font-size: 15px;
  line-height: 18px;
  margin: 10px;
  white-space: nowrap;
  text-transform: capitalize;
  text-align: center;
  height: 42px;
}
.addToCartButton:disabled {
  background-color: #ccc;
  color: #666;
  cursor: not-allowed;
  opacity: 0.7;
}
.removeButton {
  background: none;
  border: none;
  color: #333333;
  border: none;
  padding: 12px 20px;
  border-radius: 4px;
  letter-spacing: 0.5px;
  font-family: 'pangrammedium';
  font-weight: 400;
  cursor: pointer;
  font-size: 15px;
  line-height: 18px;
  margin-right: 0;
  height: 42px;
}

.removeButton:hover {
  color: #e27a34;
}

.buttons {
  display: flex;
  flex-direction: column;
  margin-left: 20px;

  button {
    margin-bottom: 15px;

    &:nth-last-child(1) {
      margin-bottom: 0;
    }
  }
}

// end

@media (max-width: 767px) {
  .cardheader {
    flex-direction: column;
    width: 100%;
    justify-content: flex-start;
    align-items: flex-start;

    .leftside {
      margin-bottom: 10px;
    }
  }
}

.empty-wishlist {
  text-align: center;
  margin-top: 60px;
}
.empty-wishlist figure img {
  width: 150px;
}
.empty-wishlist p {
  margin: 20px 0;
}

.btn-common {
  text-transform: uppercase;
  background: #e57200;
  text-align: center;
  border-radius: 3px;
  color: #fff;
  padding: 6px 15px;
  display: inline-block;
}
